.exhibmanV2 {
	.alphabet-filter {
		margin-bottom: 20px;
		.alphabet {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 1px solid black;
			@media (max-width: $mobile-width) {
				justify-content: center;
				.alphabet__letter {
					padding: 0 4px !important;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}


			.alphabet__letter {
				width: auto;
				max-width: unset;
				padding: 0;
				cursor: pointer;
				transition: font-weight 0.1s linear;
				&--active {
					font-weight: bold;
				}
			}
		}
	}
}
