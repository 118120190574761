@import "./elem/slide", "./elem/alphabet", "./elem/control-col", "./profile", "./css-file-icons", "./elem/meta-info-col", "./product",
	"./override/override";
.exhibmanV2 {
	&.exhibman-list{
		@media (min-width: 992px) {
			margin-top: 20px;
		}
	}
	.viewport-col {
	    	.control-col__sponsor-top{
		
			@media (max-width: 767.98px) { 
				display: none!important;
			}
		} 
		.viewport__header {
			//margin-bottom: 10px;
			h3 {
				padding: 0;
				margin: 0;
			}
		}
	}
	.router-link {
		&:hover {
			text-decoration: none;
		}
	}
}
#root {
    min-height: 75vh;
	@media (max-width: $mobile-width) {
		min-height: 30vh !important;
	}
	
}
