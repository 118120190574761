.exhibmanV2--BRE {
    $product-header-colour: #0a3d52;
    .product-header {
        h2 a {
            color: $product-header-colour;
        }
    }
    .exhibitor-slide {
        &.exhibitor-slide--BRE {
            .exhibitor-slide__side-flag {
                background: #0c3353;
            }
        }
    }
    .control-col__reg-link {
        a {
           // text-transform: uppercase;
        }
    }
}
